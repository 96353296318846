import { objectDuckCreator } from '@atc/modular-redux';

import { flpDuckPreset } from '@/ducks/presets';

const flpCitiesCrawlPathsDuck = objectDuckCreator({
    ...flpDuckPreset,
    store: 'flpCitiesCrawlPaths',
    initialState: {
        citiesData: {},
    },
});

export default flpCitiesCrawlPathsDuck;
