import React from 'react';

import _isEmpty from 'lodash/isEmpty';

import { ListColumns } from 'reaxl';
import { useFeatures } from 'reaxl-features';

function CrawlPathNationalLinks({
    conditionData,
    pricingData,
    stylesData,
    hasZip,
}) {
    const {
        condition_price_footer: [isConditionPriceFooterLinksEnabled],
        crawlPath: [, {
            styleDataWithZip: displayStyleData,
        }],
    } = useFeatures(['condition_price_footer', 'crawlPath']);

    const listColumns = (items) => (
        <ListColumns
            className="list-unstyled"
            xs={1}
            sm={3}
            md={4}
            lg={4}
            items={items}
        />
    );
    /*  Render conditionData, pricingData, stylesData  crawlpath for National only.  */
    const renderListItem = (item, itemType) => (
        <a
            key={item.link}
            href={item.link}
            title={item.subText}
            data-qaid={'lnk-' + itemType + '-' + item.searchParamCode}
            className="link-undecorated"
        >
            {item.subText}
        </a>
    );

    const renderCrawlPath = (crawlPathType, crawlData) => {
        if (_isEmpty(crawlData) || !crawlData.crawlLinks) {
            return null;
        }

        const items = crawlData.crawlLinks.map((item) => renderListItem(item, crawlPathType));
        return (
            <div>
                <h2
                    className="margin-top-4 margin-bottom-0 display-inline-block text-size-500 text-bold"
                    data-qaid={'lbl-SRPCrawlPathFooterTitle-' + crawlPathType}
                >
                    {crawlData.listTitle}
                </h2>
                <div>
                    {listColumns(items)}
                </div>
            </div>
        );
    };

    return (
        <>
            {!hasZip && (
                <>
                    {!!isConditionPriceFooterLinksEnabled && conditionData && renderCrawlPath('condition', conditionData)}
                    {!!isConditionPriceFooterLinksEnabled && pricingData && renderCrawlPath('pricing', pricingData)}
                    {stylesData && renderCrawlPath('styles', stylesData)}
                </>
            )}
            {
                // keep additional path always display
                hasZip && displayStyleData && stylesData && renderCrawlPath('styles', stylesData)
            }
        </>
    );
}

export default CrawlPathNationalLinks;
